import React, { useState, useEffect } from 'react'
import { Modal, Checkbox } from 'antd'

import { LcStorage } from '../../../utils/storage'
import {
    getAccountIdParam, checkInternetWorking, getCompanyCodeParam
} from '../../../contexts/functions'
import stringJp from '../../../constants/string'
import { CHECKBOX_HAD_CREDIT } from '../../../constants'
import "./indexModalCreditCard.scss"

const ModalCreditCardStatus = ({ visible, setVisible }) => {
    const companyCode = getCompanyCodeParam()

    const [isCheck, setIsCheck] = useState(
        LcStorage.getLocalJson(CHECKBOX_HAD_CREDIT)?.checked ?? false
    )

    useEffect(() => {
        if (isCheck) {
            setVisible(false)
            setIsCheck(false)
        }
        checkInternetWorking().then(value => {
            if (!value) setVisible(false)
        })
    }, [])

    const handleCheckNotShowAgain = (e) => setIsCheck(e.target.checked)

    const handleClickBtnPayPay = () => {
        LcStorage.setLocalJson(CHECKBOX_HAD_CREDIT, {
            accId: getAccountIdParam(),
            checked: isCheck
        })
        setVisible(false)
    }

    const handleClickBtnCredit = () => {
        setVisible(false)
        // Redirect to screen "credit setting" in app Scan&Go
        window.location.assign(process.env.REACT_APP_CREDIT_CARD_DEEP_LINK)
    }

    return (
        <Modal
            title=""
            className="md-check-credit-card"
            centered
            open={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            closable={false}
            maskClosable={false}
        >
            <div className="md-body-wrapper">
                <div className="md-body-content">
                    <h3 className="modal-title">
                        {stringJp.title_credit_card_status}
                    </h3>
                    <p className="md-message-content">
                        {stringJp.msg_credit_card_status_1}
                    </p>
                    <p className="md-message-content">
                        {stringJp.msg_credit_card_status_2}
                    </p>
                    {companyCode && (
                        <p className="md-message-content">
                            {stringJp[`${companyCode}`]?.msg_credit_card_status_3}
                        </p>
                    )}
                </div>

                <Checkbox checked={isCheck}
                    onChange={handleCheckNotShowAgain}
                    className="checkbox-not-show-next-time">
                    {stringJp.title_checkbox}
                </Checkbox>

                {companyCode && (
                    <button
                        className="btn-payment-method"
                        onClick={handleClickBtnPayPay}>
                        {
                            stringJp[`${companyCode}`]?.btn_PayPay_or_point
                            || stringJp.btn_PayPay_or_point
                        }
                    </button>
                )}
                <button
                    // id="deeplink-credit-card"
                    className="btn-payment-method"
                    onClick={handleClickBtnCredit}>
                    {stringJp.btn_register_credit_card}
                </button>
            </div>
        </Modal>
    );
}

export default ModalCreditCardStatus