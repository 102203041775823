import React from 'react';
import { Button } from 'antd';
import "./indexButtonRegis.scss";

const ButtonRegister = (props) => {
    const {
        titleButton, handleClick, valueInput, lengthCheck, loadingBtn
    } = props

    function renderButton(opacity, event) {
        return (
            <Button
                loading={loadingBtn}
                style={{ opacity: opacity, backgroundColor:'#61BFDC'}}
                className="button-mess-ok"
                onClick={() => event ? event() : null}
            >
                <p className="btn-register-text"
                    style={{ opacity }}>
                    {titleButton}
                </p>
            </Button>
        )
    }

    /* 
        Because valueInput may contains space among the string 
        (as SMS auth code), so it needs to be removed all spaces
        before checking length condition  
    */
    return (
        <div 
            className="btn-register-container"
            tabIndex="0"
        >
            {valueInput?.replaceAll(/\s/g, '')?.length < lengthCheck
                ? renderButton(0.6, null)
                : renderButton(1, handleClick)
            }
        </div>
    );
}

export default ButtonRegister