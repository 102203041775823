import React, { useLayoutEffect } from 'react'
import { LcStorage } from '../../utils/storage'
import { IS_SUCCESS } from '../../constants'
import stringJp from '../../constants/string'
import { useWindowSize } from '../../contexts'
import { StyledButton } from '../../components'
import './ErrorScreen.scss'

function ErrorScreen() {
    const [, windowHeight] = useWindowSize()

    useLayoutEffect(() => {
        document.documentElement.style.setProperty(
            '--dynamic-vh', `${windowHeight * 0.01}px`
        )

        const pageBody = document.getElementById('page-body')
        document.documentElement.style.setProperty(
            '--page-body-offset-top', `${pageBody?.offsetTop}px`
        )
    }, [windowHeight])

    const handleRedirect = () => {
        // Exit Loyalty webview. Redirect to app Scan&Go
        window.location.assign(process.env.REACT_APP_RANKS_DEEP_LINK)
    }

    return (
        <div className='screen-container'>
            <div className='error-screen'>
                <p>{stringJp.error_message_browser_back}</p>
                <StyledButton
                    label={stringJp.title_btn_back_to_SnG_app}
                    onClick={handleRedirect}
                />
            </div>
        </div>
    )
}

export default ErrorScreen