import React from 'react'
import clsx from 'clsx'
import './StyledButton.scss'

function StyledButton({ label, className, ...props }) {

    return (
        <div className={clsx("my-button", {
            [className]: className
        })} {...props}>
            <span className='btn-label'>{label}</span>
        </div>
    )
}

export default StyledButton