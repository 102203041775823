import React, { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import { REGISTER_SCREEN } from '../../constants/path'
import { useRank } from '../../contexts/ranks/RankContext'
import { useWindowSize } from '../../contexts/useWindowSize'
import { Header, ModalError, ModalReJoinPrevRank } from '../../components'
import './MainLayout.scss'

function MainLayout(props) {
    const {
        children, isLoading, className,
        screenTitle, color, styleHeader,
        openMdError, setOpenMdError, errorMsg
    } = props

    const { openMdRejoinPrevRank, setOpenMdRejoinPrevRank } = useRank()

    const location = useLocation()
    const [, windowHeight] = useWindowSize()

    useLayoutEffect(() => {
        document.documentElement.style.setProperty(
            '--dynamic-vh', `${windowHeight * 0.01}px`
        )

        const pageBody = document.getElementById('page-body')
        document.documentElement.style.setProperty(
            '--page-body-offset-top', `${pageBody?.offsetTop}px`
        )
    }, [windowHeight])

    return (
        <div className={'screen-container ' + className}>
            {location?.pathname !== REGISTER_SCREEN && (
                <Header
                    screenTitle={screenTitle}
                    color={color}
                    style={styleHeader}
                    isLoading={isLoading}
                />
            )}

            <div id='page-body' className='page-body-container'>
                <Spin spinning={isLoading === true} className='loading-spinner'>
                    {children}
                </Spin>
            </div>

            {openMdRejoinPrevRank && (
                <ModalReJoinPrevRank
                    visible={openMdRejoinPrevRank}
                    setVisible={setOpenMdRejoinPrevRank} />
            )}

            {openMdError && (
                <ModalError
                    visible={openMdError}
                    setVisible={setOpenMdError}
                    errorMsg={errorMsg}
                />
            )}
        </div>
    )
}

export default MainLayout