import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import clsx from 'clsx'
import { FiCheck } from 'react-icons/fi'
import { CgClose } from 'react-icons/cg'
import { ExclamationOutlined } from '@ant-design/icons'

import { LcStorage } from '../../../utils/storage'
import { MY_RANK } from '../../../constants'
import stringJp from '../../../constants/string'

import { getRankName, getMyRank } from '../../../contexts/functions'
import { useRank } from '../../../contexts/ranks/RankContext'
import { outRanking } from '../../../contexts/actions/memberInfo'
import { StyledButton, ButtonCall, ButtonContactEmail } from '../../'
import Step1 from './Step1'
import Step2 from './Step2'
import AccountInfo from './AccountInfo'
import "./indexConfirmModal.scss"

const ModalConfirmOutRank = ({ visible, setVisible, refetchRank }) => {
    const { userInfo, resetContext } = useRank()
    const currentRankId = getMyRank()?.account_rank_group_id

    const [timeOfConfirmation, setTimeOfConfirmation] = useState(1)
    const [isAccept, setIsAccept] = useState(false)
    const [isCheck, setIsCheck] = useState(false)

    const [isOutRankSuccess, setIsOutRankSuccess] = useState(null)
    const [errMsg, setErrMsg] = useState('')
    const [loadingBtn, setLoadingBtn] = useState(false)

    

    const resetModal = () => {
        setIsAccept(false)
        setIsCheck(false)
        setVisible(false)
        setIsOutRankSuccess(null);
    }

    const handleClickBtnOutRank = () => {
        if (timeOfConfirmation === 1)
            setTimeOfConfirmation(timeOfConfirmation + 1)
        else if (timeOfConfirmation === 2) {
            const dataRpOutRank = res => {
                setLoadingBtn(false)
                if (res?.status === 200 && res?.data?.success) {
                    setIsOutRankSuccess(true)
                }
                else {
                    if (res !== 'Network Error') {
                        setErrMsg(res?.data?.message)
                        setIsOutRankSuccess(false)
                    }
                }
            }
            setLoadingBtn(true)
            outRanking(dataRpOutRank, userInfo?.rankId)
        }
    }

    const handleExitOutRank = () => {
        resetModal()

        // Step 1 & 2: Notice & Confirm
        if (!isAccept || isOutRankSuccess === null) return

        // Step 3: Result (Success/Failure)
        if ([true, false].includes(isOutRankSuccess)) {
            LcStorage.removeLocal(MY_RANK)
            resetContext()

            if (refetchRank && typeof refetchRank === 'function')
                refetchRank()

            // Exit Loyalty webview. Redirect to app Scan&Go
            window.location.assign(process.env.REACT_APP_RANKS_DEEP_LINK)
        }
    }

    const handleClickBtnCancel = () => {
        handleExitOutRank()
    }

    const handleRedirect = () => {
        resetModal()
        resetContext()
        LcStorage.removeLocal(MY_RANK)
    }

    return (
        <Modal
            title=""
            className="md-confirm-exit-rank"
            centered
            open={visible}
            onCancel={handleExitOutRank}
            footer={null}
            closable={false}
            maskClosable={false}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-confirm-wrapper">
                    <div className="icon-status-confirm">
                        {isOutRankSuccess === null ?
                            <ExclamationOutlined className="icon-status" /> :
                            isOutRankSuccess === true ?
                                <FiCheck className="icon-status" /> :
                                <CgClose className="icon-status" style={{ color: '#FF7875' }} />
                        }
                    </div>
                </div>

                <div className="md-body-content">
                    {isOutRankSuccess === null &&
                        <CgClose className="btn-close-icon-times"
                            onClick={handleExitOutRank} />
                    }
                    {!isAccept ?    // Step 1: Notice
                        <Step1 isCheck={isCheck}
                            setIsCheck={setIsCheck}
                        />
                        : isOutRankSuccess === null ?   // Step 2: Confirm
                            <Step2
                                rankName={userInfo?.rankName}
                                currentRankId={currentRankId}
                                timeOfConfirmation={timeOfConfirmation}
                            />
                            : isOutRankSuccess === true ?   // Step 3a: Result (Successful)
                                <>
                                    <p className="md-title">
                                        {stringJp.title_modal_success_out_rank}
                                    </p>
                                    <AccountInfo userInfo={userInfo} />
                                    <p className="md-message-center">
                                        {getRankName(currentRankId, '', false) || userInfo?.rankName}
                                        <br />
                                        {stringJp.msg_out_rank_success_1}
                                        <br />
                                        {stringJp.msg_out_rank_success_2}
                                    </p>
                                </>
                                :   // Step 3b: Result (Failed)
                                <>
                                    <p className="md-title">
                                        {stringJp.title_modal_err_out_rank}
                                    </p>
                                    <AccountInfo userInfo={userInfo} />
                                    <p className="md-message-center">
                                        {errMsg || (
                                            <>
                                                {stringJp.msg_out_rank_fail}
                                                <br />
                                                {stringJp.msg_failure_ask_call_center}
                                                <br />
                                                {stringJp.msg_kudasai}
                                            </>
                                        )}
                                    </p>
                                </>
                    }
                </div>

                {isOutRankSuccess === null ? (
                    <div className="btn-group-container">
                        <Button 
                            style={{ height: 60}} 
                            className="btn-cancel" 
                            onClick={handleClickBtnCancel}>
                            {stringJp.text_button_cancel}
                        </Button>

                        {!isAccept ? (
                            <div
                                style={{ opacity: isCheck ? 1 : 0.6 }}
                                className="btn-continue"
                                onClick={isCheck ? () => { setIsAccept(true) } : null}
                            >
                                {stringJp.btn_agree}
                            </div>
                        ) : (
                            <Button
                                loading={loadingBtn}
                                style={{ height: 60}}
                                className="btn-continue"
                                onClick={handleClickBtnOutRank}
                            >
                                {stringJp.text_button_out_rank}
                            </Button>
                        )}
                    </div>
                ) : (
                    <>
                        {!isOutRankSuccess && (
                            <>
                                <ButtonCall />
                                <ButtonContactEmail handleRedirect={handleRedirect} />
                            </>
                        )}

                        <StyledButton
                            label={stringJp.title_btn_exit}
                            onClick={handleExitOutRank}
                            className={clsx('btn-close', {
                                'failure': !isOutRankSuccess
                            })}
                            style={{
                                background: !isOutRankSuccess ? '#FF7875' : '##5EBBD8'
                            }}
                        />
                    </>
                )}
            </div>
        </Modal>
    );
}

export default ModalConfirmOutRank; 