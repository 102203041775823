import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import {
    ERROR_SCREEN,
    HOME_SCREEN, PAYMENT_SCREEN
} from '../../../constants/path'
import {
    StyledButton, openModalNoInternet,
    ModalStatusPayment, PDFViewer
} from '../../../components'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { LcStorage } from '../../../utils/storage'
import stringJp from '../../../constants/string'
import { ACTION, AGREE_POLICY, IS_SUCCESS } from '../../../constants'
import { useWindowSize } from '../../../contexts'
import { checkInternetWorking, getSelectionState } from '../../../contexts/functions'
import { useRank } from '../../../contexts/ranks/RankContext'
import { useLastLocation } from '../../../contexts/location/LastLocationContext'
import { joinRankBronze, getPaymentMethods } from '../../../contexts/actions/memberInfo'
import MainLayout from '../../../layout/MainLayout'
import './indexPolicyScreen.scss'

function PolicyScreen() {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const currentLocation = useLocation()
    const { updateLastLocation } = useLastLocation()
    const {
        selectedRank, setSelectedRank,
        setPointBalance, setPaymentMethods
    } = useRank()
    const [windowWidth, windowHeight] = useWindowSize()
    const [footerHeight, setFooterHeight] = useState(0)
    const [isCheck, setIsCheck] = useState(false)
    const [isOnline, setIsOnline] = useState(false)
    const [isPolicyLoaded, setIsPolicyLoaded] = useState(isOnline && false)
    const [disable, setDisable] = useState(false)
    const [openMdError, setOpenMdError] = useState(false)
    const [openModalPaymentFailed, setOpenModalPaymentFailed] = useState(false)
    const [errorMessageIntroduceCode, setErrorMessageIntroduceCode] = useState(null)

    useEffect(() => {
        const footerEl = document.getElementById('policy-footer')
        setFooterHeight(footerEl?.offsetHeight)
    }, [windowHeight])

    // Navigate to screen Error

    useEffect(() => {
        if (+LcStorage.getLocalJson(IS_SUCCESS) === 1) {
            // updateLastLocation(currentLocation.pathname)
            navigate(`/${ERROR_SCREEN}`)    // , { replace: true }
        }
        checkInternetWorking().then(value => {
            setIsOnline(value)
            if (!value) openModalNoInternet()
        })
    }, [])

    useEffect(() => {
        if (!currentLocation?.search) return

        LcStorage.removeLocal(AGREE_POLICY)
        const selectionState = getSelectionState()

        if (selectedRank) return

        if (!selectionState?.rank) {
            navigate(`/${HOME_SCREEN}`)
            return
        }
        setSelectedRank(selectionState?.rank)
    }, [currentLocation?.search])

    const handleJoinFreeRank = () => {
        const handleDataRp = res => {
            setDisable(false);
            if (res?.status === 200 && res?.data?.success) {
                LcStorage.setLocalJson(IS_SUCCESS, 1)
                updateLastLocation(currentLocation.pathname)
                navigate({
                    pathname: `/success`,
                    search: createSearchParams({
                        action: searchParams.get(ACTION) || ''
                    }).toString()
                }, {
                    state: { introduced_point: res?.data?.introduced_point },
                })
            }
            else {
                if (res?.status === 412) {
                    setErrorMessageIntroduceCode(res?.data?.message)
                }
                setOpenModalPaymentFailed(true)
            }
        }
        setDisable(true);
        joinRankBronze(handleDataRp, selectedRank?.account_rank_group_id, LcStorage.getLocalJson("introduce_code"))
    }

    const checkPaymentMethods = () => {

        const handleDataResPaymentMethod = res => {
            setDisable(false);
            if (res?.status === 200 && res?.data?.success) {
                const { point_balance, payment_point, payment_acs, payment_paypay, select_grant_point, point_money_balance, payment_point_money } = res?.data
                setPointBalance(point_balance)
                setPaymentMethods({
                    point: payment_point,
                    creditCard: {
                        usable: payment_acs
                    },
                    payPay: payment_paypay,
                    grantPoint: select_grant_point,
                    pointMoneyBalance: point_money_balance,
                    paymentPointMoney: payment_point_money
                })
                LcStorage.setLocalJson(AGREE_POLICY, isCheck)

                updateLastLocation(currentLocation.pathname)
                navigate({
                    pathname: `/${PAYMENT_SCREEN}`,
                    search: createSearchParams({
                        action: searchParams.get(ACTION) || ''
                    }).toString()
                })
            }
            else setOpenMdError(true)
        }

        setDisable(true);
        getPaymentMethods(handleDataResPaymentMethod)
    }

    // Need enhancement (check whether there're any free ranks or not)
    const handleClickBtnPolicy = () => {
        if (!selectedRank) {
            setOpenMdError(true)
            return
        }
        if (+selectedRank?.admission_fee === 0)   // free rank
            handleJoinFreeRank()
        else
            checkPaymentMethods()
    }

    const handleChangeCheckbox = event => {
        setIsCheck(event?.target?.checked)
    }

    return (
        <MainLayout className="policy-screen"
            screenTitle={stringJp.title_policy}
            openMdError={openMdError}
            setOpenMdError={setOpenMdError}
            errorMsg={stringJp.err_check_payment_method}
        >
            <div className="screen-body-wrapper">
                <div id='pdf-container'
                    className='policy-content-wrapper'
                    style={{
                        height: `calc(100% - (${windowWidth < 768 ? '1' : '2'}px + ${footerHeight}px))`,
                        overflow: 'auto'
                    }}
                >
                    <PDFViewer onSuccess={() => setIsPolicyLoaded(isOnline && true)} />
                </div>

                <div className='footer-divider' />

                <div id='policy-footer' className="footer-wrapper">
                    <Checkbox checked={isCheck}
                        onChange={handleChangeCheckbox}
                        disabled={!isPolicyLoaded}
                        className='chk-agree-policy'>
                        {stringJp.txt_policy_agreement}
                    </Checkbox>

                    <StyledButton
                        label={stringJp.btn_agree}
                        onClick={isCheck ? handleClickBtnPolicy : null}
                        style={{ 
                            opacity: isCheck ? 1 : 0.6, 
                            pointerEvents: disable && 'none'
                        }}
                    />
                </div>
            </div>

            {openModalPaymentFailed &&
                <ModalStatusPayment
                    visible={openModalPaymentFailed}
                    setVisible={setOpenModalPaymentFailed}
                    errorMsgDefault={errorMessageIntroduceCode}
                />
            }
        </MainLayout>
    )
}

export default PolicyScreen
