import React from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import NotFound from '../pages/NotFound'
import {
    RegisterScreen,
    HomeScreen,
    SelectionScreen,
    PolicyScreen,
    PaymentScreen,
    RegistrationSuccess,
    ContactScreen,
    ErrorScreen
} from '../pages'
import PrivateRoutes from './PrivateRoutes'
import PendingScreen from '../pages/PaymentScreen/Pending/PendingScreen'

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Outlet />}>
                    <Route index element={<RegisterScreen />} />

                    <Route path='/' element={<PrivateRoutes />}>
                        <Route path='/contact' element={<ContactScreen />} />
                        <Route path='/member-info' element={<Outlet />}>
                            <Route index element={<HomeScreen />} />
                            <Route path='/member-info/selection' element={<SelectionScreen />} />
                            <Route path='/member-info/policy' element={<PolicyScreen />} />
                            <Route path='/member-info/payment' element={<PaymentScreen />}/>
                        </Route>
                    </Route>

                    <Route path='/pending' element={<PendingScreen />} />
                    <Route path='/success' element={<RegistrationSuccess />}/>
                    <Route path="/error" element={<ErrorScreen />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter