import { createContext, useContext, useRef } from "react"

export const LastLocationContext = createContext()

export const useLastLocation = () => useContext(LastLocationContext)

const LastLocationContextProvider = ({ children }) => {
    const lastVisitedLocation = useRef(null)

    const updateLastLocation = location => {
        lastVisitedLocation.current = location
    }
    return (
        <LastLocationContext.Provider
            value={{ lastVisitedLocation, updateLastLocation }}
        >
            {children}
        </LastLocationContext.Provider>
    );
}

export default LastLocationContextProvider