import React from 'react';
import { Modal } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons'
import { LcStorage } from '../../../utils/storage'
import stringJp from '../../../constants/string'
import { SELECTION_STATE } from '../../../constants'
import { useWindowSize } from '../../../contexts'
import { useRank } from '../../../contexts/ranks/RankContext'
import { getCompanyCodeParam, getSelectionState } from '../../../contexts/functions'
import { ButtonCall, ButtonContactEmail } from '../../'
import "./ModalReJoinPrevRank.scss";

const ModalReJoinPrevRank = ({ visible, setVisible }) => {
    const { setSelectedRank } = useRank()
    const [windowWidth] = useWindowSize()

    function closeModal() {
        setVisible(false)
        setSelectedRank(null)

        LcStorage.setLocalJson(
            SELECTION_STATE, { ...getSelectionState(), rank: null }
        )
    }

    return (
        <Modal
            title=""
            className="md-warning-rejoin-rank"
            centered
            open={visible}
            onCancel={closeModal}
            footer={null}
            closable={false}
            maskClosable={false}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-confirm-wrapper">
                    <div className="icon-status-confirm">
                        <ExclamationOutlined className="icon-status" />
                    </div>
                </div>

                <div className="md-body-content">
                    <p className="md-title">{stringJp.title_md_rejoin_rank_warning}</p>
                    <p className="md-message-warning">
                        {stringJp[`${getCompanyCodeParam()}`].txt_rank_member}
                        {stringJp.txt_md_rejoin_rank_content_1}
                        {windowWidth < 768 && <br />}
                        {stringJp.txt_md_rejoin_rank_content_2}
                        {windowWidth < 768 && <br />}
                        {stringJp.txt_md_rejoin_rank_content_3}
                        <br />
                        {stringJp.txt_md_rejoin_rank_content_4}
                        {windowWidth < 768 && <br />}
                        {stringJp.txt_md_rejoin_rank_content_5}
                    </p>
                </div>

                <div className="btn-group-container">
                    <ButtonCall />
                    <ButtonContactEmail handleRedirect={closeModal} />

                    <div className="btn-exit" onClick={closeModal}>
                        {stringJp.title_btn_exit}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalReJoinPrevRank; 