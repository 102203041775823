import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CONTACT_SCREEN } from '../../../constants/path'
import stringJp from '../../../constants/string'
import { useLastLocation } from '../../../contexts/location/LastLocationContext'
import './ButtonContactEmail.scss'

function ButtonContactEmail({ handleRedirect, label }) {
    const navigate = useNavigate()
    const currentLocation = useLocation()
    const { updateLastLocation } = useLastLocation()

    const handleNavigate = () => {
        if (handleRedirect) handleRedirect()
        updateLastLocation(currentLocation.pathname)

        navigate(`/${CONTACT_SCREEN}`)
    }

    return (
        <div onClick={handleNavigate} className='btn-contact-email'>
            {label ?? stringJp.title_btn_contact_via_email}
        </div>
    )
}

export default ButtonContactEmail