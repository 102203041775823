import React, { useEffect } from 'react'
import { Modal } from 'antd'
import { CgClose } from 'react-icons/cg'

import { checkInternetWorking } from '../../../contexts/functions'
import stringJp from '../../../constants/string'
import "./ModalError.scss"

const ModalError = (props) => {
    const { visible, setVisible, errorMsg } = props

    useEffect(() => {
        checkInternetWorking().then(value => {
            if (!value) setVisible(false)
        })
    }, [])

    const handleExit = () => setVisible(false)

    return (
        <Modal
            title=""
            className="md-error"
            centered
            open={visible}
            onCancel={handleExit}
            footer={null}
            closable={false}
            maskClosable={false}
            width={400}
        >
            <div className="md-body-wrapper">
                <div className="icon-status-wrapper">
                    <div className="icon-status-payment">
                        <CgClose className="icon-status" />
                    </div>
                </div>

                <div className="md-body-content">
                    <p className="md-title">
                        {stringJp.title_md_err}
                    </p>
                    <p className="md-message">
                        {errorMsg}
                    </p>
                </div>

                <div className="btn-close-wrapper">
                    <div className="btn-close" onClick={handleExit}>
                        <p>{stringJp.title_btn_exit}</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalError; 