export class LcStorage {
    static getLocalJson(key) {
        try {
            let value = localStorage.getItem(key)
            return value === '' ? value : (value ? JSON.parse(value) : null)
        } catch (e) {
            console.error(e)
        }

        return null
    }

    static setLocalJson(key, value) {
        try {
            let valueStr = (value === null || value === undefined) ? '' : JSON.stringify(value);
            localStorage.setItem(key, valueStr)
        } catch (e) {
            console.error(e)
        }
    }

    static removeLocal(key) {
        localStorage.removeItem(key)
    }

    static clearLocal() {
        localStorage.clear()
    }
}
