import React from 'react'
import stringJp from '../../../constants/string'
import { getRankName, getOutRankIframeUrl } from '../../../contexts/functions'
import "./indexConfirmModal.scss"

function Step2(props) {
    const { rankName, currentRankId, timeOfConfirmation } = props

    return (
        <>
            <p className="md-title">
                {stringJp.title_modal_confirm_out_rank}
            </p>

            {timeOfConfirmation === 1 ? (
                <>
                    <div className="md-message-center">
                        {getRankName(currentRankId, '', false) || rankName}
                        {' '}
                        {stringJp.cf_out_rank_mes_1}
                        <br />
                        {stringJp.cf_out_rank_mes_2}
                        <br />
                        {stringJp.cf_out_rank_mes_3}
                        <br />
                        {stringJp.cf_out_rank_mes_4}
                    </div>

                    <div className='md-iframe-wrapper'>
                        <iframe
                            src={getOutRankIframeUrl(currentRankId)}
                            className='md-iframe'
                            frameBorder="0" loading='lazy' />
                    </div>
                </>
            ) : timeOfConfirmation === 2 && (
                <div className="md-message-center">
                    {getRankName(currentRankId, '', false) || rankName}
                    {' '}
                    {stringJp.cf_out_rank_mes_1}
                    <br />
                    {stringJp.cf_out_rank_mes_5}
                </div>
            )}
        </>
    )
}

export default Step2