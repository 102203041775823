import React from 'react'
import stringJp from '../../constants/string'
import './NotFound.scss'

function NotFound() {

    return (
        <div className='not-found-screen screen-container'>
            {stringJp.msg_page_not_found}
        </div>
    )
}

export default NotFound
