
import {
    GET_LIST_RANKS,
    GET_MEMBER_RANKS,
    GET_PAYMENT_METHOD,
    CHECK_PAYPAY_PAYMENT_STATUS,
    GET_PAYPAY_URL,
    JOIN_RANK,
    JOIN_FREE_RANK,
    OUT_RANKING,
    VERIFY_INTRODUCE_CODE
} from "../../constants/hrefApp";
import HttpInstance from "../../services/AxiosInstance";
import { 
    getAccountIdParam, 
    getCompanyCodeParam, 
    getStoreCodeParam,
    getInStoreCodeParam
} from "../functions";
import { LcStorage } from "../../utils/storage";
import { ACCOUNT_ID, COMPANY_CODE, IN_STORE_CODE, STORE_CODE } from "../../constants";

let storeCodeGetParam = getStoreCodeParam() === "null" ? null:getStoreCodeParam();
let inStoreCodeGetParam = getInStoreCodeParam() === "null" ? null:getInStoreCodeParam();
// 'Home' screen & 'RegistrationSuccess' screen
const getMemberRanks = handleDataRp => {
    const dataBodyReq = {
        "account_id": getAccountIdParam(),
        "company_code": getCompanyCodeParam()
    }

    HttpInstance()
        .post(GET_MEMBER_RANKS, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

const getMemberRanksScreenSuccess = handleDataRp => {
    const dataBodyReq = {
        "company_code": LcStorage.getLocalJson(COMPANY_CODE),
        "account_id": LcStorage.getLocalJson(ACCOUNT_ID),
    }

    HttpInstance()
        .post(GET_MEMBER_RANKS, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}
// 'Selection' screen
const getListRank = handleDataRp => {
    const dataBodyReq = {
        "company_code": getCompanyCodeParam(),
        "store_code": storeCodeGetParam,
        "instore_code": inStoreCodeGetParam
    }

    HttpInstance(false)
        .post(GET_LIST_RANKS, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

const getPaymentMethods = handleDataRp => {
    const dataBodyReq = {
        "account_id": getAccountIdParam(),
        "company_code": getCompanyCodeParam(),
        "store_code": storeCodeGetParam,
        "instore_code": inStoreCodeGetParam
    }

    HttpInstance(false)
        .post(GET_PAYMENT_METHOD, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

// 'Policy' screen
const joinRankBronze = (handleDataRp, rankId, introduceCode) => {
    const dataBodyReq = {
        "company_code": getCompanyCodeParam(),
        "account_id": getAccountIdParam(),
        "store_code": storeCodeGetParam,
        "instore_code": inStoreCodeGetParam,
        "account_rank_group_id": rankId,
        "introduce_code": introduceCode
    }

    HttpInstance()
        .post(JOIN_FREE_RANK, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

// 'Payment' screen
const joinOrUpdateRank = (handleDataRp, reqData) => {
    const dataBodyReq = {
        "company_code": getCompanyCodeParam(),
        "account_id": getAccountIdParam(),
        "store_code": storeCodeGetParam,
        "instore_code": inStoreCodeGetParam,
        "account_rank_group_id": reqData?.rankId,
        "use_point": reqData?.didUsePoint,
        "use_point_amount": reqData?.usedPointsAmount,
        "use_valuecard": reqData?.usedValueCard,
        "introduce_code": reqData?.introduceCode
    }

    HttpInstance()
        .post(JOIN_RANK, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

const getPayPayRequestUrl = (handleDataRp, reqData) => {
    const dataBodyReq = {
        "company_code": getCompanyCodeParam(),
        "account_id": getAccountIdParam(),
        "store_code": storeCodeGetParam,
        "instore_code": inStoreCodeGetParam,
        "account_rank_group_id": reqData?.rankId,
        "use_point": reqData?.didUsePoint,
        "use_point_amount": reqData?.usedPointsAmount,
        "success_url": reqData?.successURL,
        "cancel_url": reqData?.cancelURL,
        "error_url": reqData?.errorURL,
        "introduce_code": reqData?.introduceCode
    }

    HttpInstance()
        .post(GET_PAYPAY_URL, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

const checkPayPayPaymentStatus = (handleDataRp, orderId) => {
    const dataBodyReq = {
        "company_code": LcStorage.getLocalJson(COMPANY_CODE),
        "account_id": LcStorage.getLocalJson(ACCOUNT_ID),
        "store_code": LcStorage.getLocalJson(STORE_CODE),
        "instore_code": LcStorage.getLocalJson(IN_STORE_CODE),
        "order_id": orderId,
        "introduce_code": LcStorage.getLocalJson("introduce_code")
        // "company_code": getCompanyCodeParam(),
        // "account_id": getAccountIdParam(),
        // "order_id": orderId
    }

    HttpInstance(false)
        .post(CHECK_PAYPAY_PAYMENT_STATUS, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        });
}

// 'Out rank' modal
const outRanking = (handleDataRp, rankId) => {
    const dataBodyReq = {
        "company_code": getCompanyCodeParam(),
        "account_id": getAccountIdParam(),
        "account_rank_group_id": rankId
    }

    HttpInstance()
        .post(OUT_RANKING, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            const errorObj = JSON.parse(JSON.stringify(err))

            if (errorObj?.message === 'Network Error')
                handleDataRp(errorObj?.message)
            else
                handleDataRp(err?.response)
        });
}

const verifyIntroduceCode = (handleDataRp, introduce_code) => {
    const dataBodyReq = {
        "introduce_code": introduce_code,
        "company_code": getCompanyCodeParam(),
        "account_id": getAccountIdParam()
    }

    HttpInstance()
        .post(VERIFY_INTRODUCE_CODE, dataBodyReq)
        .then((res) => {
            handleDataRp(res)
        }).catch((err) => {
            handleDataRp(err?.response);
        })
}

export {
    getMemberRanks,
    getListRank,
    getPaymentMethods,
    joinRankBronze,
    joinOrUpdateRank,
    outRanking,
    getPayPayRequestUrl,
    checkPayPayPaymentStatus,
    getMemberRanksScreenSuccess,
    verifyIntroduceCode
}