const AUTH_CODE_LENGTH = 6

const USER_KEY = 'user'

const ACCOUNT_ID = 'account_id'
const COMPANY_CODE = 'company_code'

const STORE_CODE = 'store_code'
const IN_STORE_CODE = 'instore_code'

const SYSTEM_ID = 'system_id'

const CHECKBOX_HAD_CREDIT = 'not_open_modal_credit'
const HAD_CREDIT_CARD = 'had_credit'
const ACCOUNT_NAME = 'name'
const MY_RANK = 'my_rank'
const PAST_RANKS = 'past_ranks'
const PAYPAY_ORDER_ID = 'paypay'
const AGREE_POLICY = 'ok'
const SELECTION_STATE = 'ss'
const IS_SUCCESS = 's'

const ACTION = 'action'
const ACTION_TYPE = {
    REGISTER: 'register',
    CHANGE: 'change',
    RENEWAL: 'renewal',
}

export {
    USER_KEY,
    AUTH_CODE_LENGTH,
    ACCOUNT_ID, COMPANY_CODE, SYSTEM_ID,
    ACCOUNT_NAME,
    CHECKBOX_HAD_CREDIT, HAD_CREDIT_CARD,
    AGREE_POLICY,
    SELECTION_STATE,
    IS_SUCCESS,
    MY_RANK, PAST_RANKS,
    PAYPAY_ORDER_ID,
    ACTION, ACTION_TYPE, 
    STORE_CODE, IN_STORE_CODE
}