
import axios from "axios"
import { checkInternetWorking } from "../contexts/functions"
import { openModalNoInternet } from "../components"
import { TrackGoogleAnalyticsEvent } from "../utils/google-analytics";

const AxiosInstance = (isNeedToCheckInternet = true) => {
    const HttpInstance = axios.create({
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`
    });

    // Request interceptor
    HttpInstance.interceptors.request.use((req) => {
        req.headers.Accept = `application/json`;
        req.headers['Content-Type'] = `application/json`;

        if (process.env.REACT_APP_BEAR_TOKEN) {
            req.headers.Authorization = `Bearer ${process.env.REACT_APP_BEAR_TOKEN}`;
        };

        if (isNeedToCheckInternet) {
            checkInternetWorking().then(isOnline => {
                if (!isOnline)
                    openModalNoInternet()
            })
        }

        return req
    }, (error) => {
        return Promise.reject(error);
    });

    // Response interceptor
    HttpInstance.interceptors.response.use(function (response) {
        TrackGoogleAnalyticsEvent(response);
        return response;
    }, async function (error) {
        TrackGoogleAnalyticsEvent(error.response);
        return Promise.reject(error);
    })

    return HttpInstance;
};

export default AxiosInstance;