import React from 'react'
import {
    useNavigate, useLocation, createSearchParams, useSearchParams
} from "react-router-dom"
import { BiArrowBack } from 'react-icons/bi'

import { getCompanyCodeParam, getSelectionState } from '../../contexts/functions'
import {
    HOME_SCREEN, PAYMENT_SCREEN, POLICY_SCREEN,
    SELECTION_SCREEN, CONTACT_SCREEN
} from '../../constants/path'
import { ACTION, ACTION_TYPE } from '../../constants'
import { COMPANY_CODES } from '../../constants/configCompany'
import { useLastLocation } from '../../contexts/location/LastLocationContext'
import './indexHeader.scss'

function Header(props) {
    const { screenTitle, color, isLoading, ...rest } = props

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const companyCode = getCompanyCodeParam()

    const location = useLocation()
    const { updateLastLocation } = useLastLocation()
    // const { setSelectedRank } = useRank()

    const handleBack = () => {
        const action = searchParams.get(ACTION)
        updateLastLocation(location.pathname)

        if (location.pathname === `/${SELECTION_SCREEN}`)
            navigate(`/${HOME_SCREEN}`)
        else if (location.pathname === `/${PAYMENT_SCREEN}`) {
            const savedAction = getSelectionState()?.action ?? action

            navigate({
                pathname: `/${POLICY_SCREEN}`,
                search: createSearchParams(
                    savedAction && savedAction !== 'null'
                        ? { action: savedAction } : {}
                ).toString()
            })
        }
        else if (location.pathname === `/${POLICY_SCREEN}`) {
            // setSelectedRank(null)

            if (companyCode === COMPANY_CODES.KASUMI) {
                if (action === ACTION_TYPE.RENEWAL)
                    navigate(`/${HOME_SCREEN}`)
                else {
                    navigate({
                        pathname: `/${SELECTION_SCREEN}`,
                        search: createSearchParams(
                            action && action !== 'null' ? { action } : {}
                        ).toString()
                    })
                }
            }
            else if (companyCode === COMPANY_CODES.MAX_VALUE || companyCode === COMPANY_CODES.MARUETSU)
                navigate(`/${HOME_SCREEN}`)
        }
        else
            navigate(-1)
    }

    return (
        <div className="header-container" {...rest}>
            <div className='header-wrapper'>
                {[
                    `/${CONTACT_SCREEN}`,
                    `/${SELECTION_SCREEN}`,
                    `/${PAYMENT_SCREEN}`,
                    `/${POLICY_SCREEN}`
                ].includes(location.pathname)
                    && (
                        <BiArrowBack
                            onClick={isLoading ? null : handleBack}
                            style={{ color }}
                            className='hd-btn-back' />
                    )}
                <p className="hd-screen-title" style={{ color }}>
                    {screenTitle}
                </p>
            </div>
        </div>
    )
}

export default Header
